<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--  送评订单    -->
      <div slot="itemApplyNoSlot" slot-scope="row">
        <span class="color-blue">{{ row.applyNo }}</span>
      </div>
<!--  送评用户    -->
      <div slot="itemRateServiceUserNameSlot" slot-scope="row">
        <span class="color-black">{{ row.userName }}</span><span class="color-gary">【{{ row.userId }}】</span>
      </div>
<!--  是否需要标版    -->
      <div slot="itemVersionServiceStatusSlot" slot-scope="row">
        <span :class="dqCheckVersionStatusClassType[row.duibanService]">
          {{ computedVersionStatusTypeText(row.duibanService) }}
        </span>
      </div>
<!--  送评图片    -->
      <div slot="itemRateServiceImageSlot" slot-scope="row">
        <div class="img-list" v-if="row.coinImagesList">
          <img
              v-for="(item, index) in row.coinImagesList"
              :key="index"
              :src="computedNotShowImage(item)"
              alt=""
              @click="handlePreviewImg(row.coinImagesList, index)"
          >
        </div>
      </div>
<!--  送评状态    -->
      <div slot="itemRateStatusSlot" slot-scope="row">
        <span :class="dTypeRateStatusClassType.get(row.ratingStatus)">
          {{ computedDTypeRateStatusTypeText(row.ratingStatus) }}
        </span>
      </div>
<!--  操作    -->
      <div slot="itemActionSlot" slot-scope="row">
        <div v-if="row.ratingStatus !== 25">
          <a-button
              size="small"
              type="primary"
              @click="handleShowLogisticsInfo(row)"
          >修改物流信息</a-button>
        </div>
        <div v-if="row.ratingStatus !== 25">
          <a-popconfirm
              title="确定取消评级-退款？"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleCancelRateServiceAndReturnPrice(row)"
          >
            <a-button
                v-if="row.payStatus && row.payStatus >= 2"
                class="mt-10"
                size="small"
                type="danger"
            >取消评级-退款</a-button>
          </a-popconfirm>
        </div>
        <div>
          <a-button
              class="mt-10"
              size="small"
              @click="handleShowRatingLine(row)"
          >明细</a-button>
        </div>
      </div>
    </a-table>
<!--  修改物流信息  -->
    <ChangeLogisticsMessagePopup
      ref="changeLogisticsMessagePopupEl"
      @change="handleChangeSuccess"
    />
<!--  评级明细  -->
    <RatingLinePopup ref="ratingLinePopupEl" @changeSuccess="handleChangeSuccess"/>
  </div>
</template>
<script>
import {dqCheckDListTableColumns} from "@/views/cmsPage/checkManage/dList/_data"
import {
  computedVersionStatusType, D_TYPE_RATE_STATUS_CLASS_TYPE_LIST,
  dqCheckVersionStatusClassType,
  dTypeRateStatusTypeMapText
} from "@/views/cmsPage/checkManage/_data"
import {addStringIfContains} from "@/untils/otherEvent"
import ChangeLogisticsMessagePopup from "@/views/cmsPage/checkManage/dList/_components/ChangeLogisticsMessagePopup"
import {canCelRateServiceAndReturnPriceByDType} from "@/views/cmsPage/checkManage/_apis"
import RatingLinePopup from "@/views/cmsPage/checkManage/dList/_components/RatingLinePopup/index.vue"

export default {
  emits: ['changeSuccess'],
  props: ['pagination', 'list'],
  components: {ChangeLogisticsMessagePopup, RatingLinePopup},
  data() {
    return {
      tableList: dqCheckDListTableColumns,
      dqCheckVersionStatusClassType,
      dTypeRateStatusClassType: D_TYPE_RATE_STATUS_CLASS_TYPE_LIST
    }
  },
  computed: {
    computedNotShowImage() {
      return (value) => addStringIfContains(value, 'x-oss', '?x-oss-process=image/resize,p_100/format,jpg')
    },
    computedVersionStatusTypeText() {
      return (value) => computedVersionStatusType(value)
    },
    computedDTypeRateStatusTypeText() {
      return (value) => dTypeRateStatusTypeMapText(value)
    },
  },
  methods: {
    /** 评级明细 */
    handleShowRatingLine(row) {
      this.$refs.ratingLinePopupEl.show(row)
    },
    /** 确定取消评级-退款 */
    async handleCancelRateServiceAndReturnPrice(row) {
      const res = await canCelRateServiceAndReturnPriceByDType({personApplyId: row.id})
      if (res.status !== '200') return
      this.$message.info(res.message || '取消成功-退款成功')
      this.handleChangeSuccess()
    },
    /** 修改物流信息 */
    handleShowLogisticsInfo(row) {
      const tempData = {
        expressNo: row.expressNo,
        logisticsId: row.logisticsId,
        personApplyId: row.id
      }
      this.$refs.changeLogisticsMessagePopupEl.show(tempData)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 大图展示 */
    handlePreviewImg(images, index) {
      const urls = images.map(el => {
        return {img_url: el}
      })
      this.$previewImg({
        list: urls,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
    /** 修改成功 */
    handleChangeSuccess() {
      this.$emit('changeSuccess')
    },
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  color: green;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>