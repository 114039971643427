<template>
  <a-modal
      v-model="showPopup"
      title="评级支付记录-退还"
      :width="400"
      ok-text="确认"
      cancel-text="取消"
      @cancel="handleCancel"
      @ok="handleConfirm"
  >
    <div class="popup-content">
      <a-form-model
          ref="ruleForm"
          :model="params"
          v-bind="layout"
      >
        <a-form-item label="最大金额">
          <div class="color-red ml-10">￥{{ maxPayMoney }}</div>
        </a-form-item>
        <a-form-item label="退款金额">
          <a-input
              allowClear
              type="number"
              v-model="params.partReturnMoney"
              class="w-200 ml-10"
              placeholder='输入需要退款的金额'
          ></a-input>
        </a-form-item>
      </a-form-model>
    </div>
  </a-modal>
</template>
<script>
import {ratingLineByReturnPriceApi} from "@/views/cmsPage/checkManage/dList/_apis"

export default {
  props: ['rateType'],
  data() {
    return {
      showPopup: false,
      maxPayMoney: 0,
      layout: {
        labelCol: { span: 4 }, //标签占5份  共24份
        wrapperCol: { span: 20 },
      },
      params: {
        id: undefined,
        partReturnMoney: undefined,
      },
    }
  },
  methods: {
    /** 展示弹窗 */
    show(data) {
      this.maxPayMoney = data.payMoney
      this.params.id = data.id
      this.showPopup = true
    },
    /** 取消 */
    handleCancel() {
      this.maxPayMoney = undefined
      this.params = {
        id: undefined,
        partReturnMoney: undefined,
      }
      this.showPopup = false
    },
    /** 确定 */
    async handleConfirm() {
      if (!this.params.partReturnMoney) {
        return this.$message.warn('请输入需要退还的金额')
      }
      if (Number(this.params.partReturnMoney) > Number(this.maxPayMoney)) {
        return this.$message.warn('退还金额不能大于最大金额')
      }
      this.$loading.show()
      const res = await ratingLineByReturnPriceApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return
      this.$emit('change')
      this.handleCancel()
    }
  }
}
</script>