import instance from "@/axios"

/** 获取d类支付明细列表 */
export const getDTypePayLineApi = async (params) => {
    return instance({
        url: '/dq_admin/ratingPayRecord/list',
        method: 'GET',
        params: params
    })
}

/** 评级支付记录-退还 */
export const ratingLineByReturnPriceApi = async (params) => {
    return instance({
        url: '/dq_admin/ratingPayRecord/returnRatingDetail',
        method: 'GET',
        params: params
    })
}