<template>
<div>
  <a-table
      :columns="tableList"
      :pagination="pagination"
      :data-source="list"
      :rowKey="(record, index) => index"
      @change="changePage"
  >
<!--  支付状态  -->
    <div slot="itemPayStatusSlot" slot-scope="row">
      <div :class="dTypeRatingLinePayTypeClassType[row.payStatus]">
        {{ computedDTypeRatingLinePayTypeMapText(row.payStatus) }}
      </div>
    </div>
<!--  金额  -->
    <div slot="itemPayMoneySlot" slot-scope="row">
      <div class="color-red">￥{{ row.payMoney }}</div>
    </div>
<!--  操作   -->
    <div slot="itemActionSlot" slot-scope="row">
      <a-button
          v-if="row.payStatus === 2 && !row.returnTime"
          size="small"
          type="danger"
          @click="handleReturnPrice(row)"
      >退还</a-button>
    </div>
  </a-table>
<!--  退款  -->
  <ChangeReturnPricePopup ref="changeReturnPricePopupEl" @chagne="handleChangeSuccess"/>
</div>
</template>
<script>
import {dqCheckDTypeLineTableColumns} from "@/views/cmsPage/checkManage/dList/_data"
import ChangeReturnPricePopup
  from "@/views/cmsPage/checkManage/dList/_components/RatingLinePopup/_components/ChangeReturnPricePopup/index.vue"
import {
  dTypeRatingLinePayTypeClassType,
  dTypeRatingLinePayTypeMapText
} from "@/views/cmsPage/checkManage/_data"

export default {
  components: {ChangeReturnPricePopup},
  emits: ['changeSuccess', 'changePage'],
  props: ['pagination', 'list'],
  data() {
    return {
      tableList: dqCheckDTypeLineTableColumns,
      dTypeRatingLinePayTypeClassType,
    }
  },
  computed: {
    computedDTypeRatingLinePayTypeMapText() {
      return (value) => dTypeRatingLinePayTypeMapText(value)
    },
  },
  methods: {
    /** 操作退款 */
    handleReturnPrice(row) {
      this.$refs.changeReturnPricePopupEl.show(row)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 修改成功 */
    handleChangeSuccess() {
      this.$emit('changeSuccess')
    },
  }
}
</script>
<style lang="scss" scoped>
</style>