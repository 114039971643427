
export const statusList = [
  { name: "待处理", value: 0, },
  { name: "已处理", value: 3, },
  { name: "已推待质检-有结果", value: 30, },
  { name: "已推待质检-无结果", value: 40, },
  { name: "已推样本库", value: 10, },
  { name: "已推底库", value: 20, },
  { name: "假币", value: 4, },
  { name: "无法辨认", value: 6, },
  { name: "加刀", value: 8, }
]

export const statusValue = (value)=>{
  const result = statusList.find(el=>{
    return value == el.value
  })
  return result ? result.name : "-"
}


export const manualMachineStatusList = [
  { name: "人工", value: 0, },
  { name: "机器", value: 1, }
]

export const manualMachineStatusValue = (index)=>{
  return manualMachineStatusList[index].name
}

export const platformTypeList = [
  { name: "小程序对版", value: 0, },
  { name: "APP对版", value: 1, },
  { name: "Android对版", value: 2, },
  { name: "IOS对版", value: 3, },
  { name: "BX对版", value: 10, },
  { name: "拍品对版", value: 80, }
]

export const visibleStatusTypeList = [
  { name: "公开对版", value: 0, },
  { name: "私密对版", value: 1, }
]

export const platformTypeValue = (index)=>{
  return platformTypeList[index].name
}


export const photographAlbumStatusList = [
  { name: "未区分", value: 0, },
  { name: "拍照", value: 1, },
  { name: "相册", value: 2, }
]

export const photographAlbumStatusValue = (index)=>{
  return photographAlbumStatusList[index].name
}



export const sectionTypeList = [
  { name: "最新好版", value: "good", },
  { name: "最新对版", value: "latest", },
  { name: "错误对版", value: "error", }
]

export const sectionTypeValue = (value)=>{
  const result = sectionTypeList.find(el=>{
    return value == el.value
  })
  return result ? result.name : "-"
}


export const recognizerVersionList = [
  { name: "v7", value: "v7" },
  { name: "v6", value: "v6" },
  { name: "v5", value: "v5", },
  { name: "v4", value: "v4", },
  { name: "v3", value: "v3", }
]


export const recognizerVersionValue = (value)=>{
  const result = recognizerVersionList.find(el=>{
    return value == el.value
  })
  return result ? result.name : "-"
}


export const markTagList = [
  // { name: "反馈", value: "feedback", },
  // { name: "专家对版", value: "professional", },
  { name: "一个答案", value: "one", },
  { name: "两个答案", value: "two", },
  { name: "多答案", value: "many", },
  { name: "低概率", value: "low", },
  { name: "盒子币", value: "shell", },
  { name: "稀少版", value: "few", },
  { name: "币识别低", value: "coin_percent_small", },
  { name: "正确对版", value: "correct", },
  { name: "一二不同", value: "OnNotEqualTo", },
  { name: "底库一比四", value: "1vs4", },
  { name: "bx错误", value: "bxerror", }
  
]
export const markTagListPool = [
  { name: "一个答案", value: "one", },
  { name: "两个答案", value: "two", },
  { name: "多答案", value: "many", },
  { name: "低概率", value: "low", },
  { name: "盒子币", value: "shell", },
  { name: "稀少版", value: "few", },
  { name: "正确对版", value: "correct", },
  { name: "一二不同", value: "OnNotEqualTo", },
  { name: "底库一比四", value: "1vs4", },
]


export const markTagValue = (value)=>{
  const result = markTagList.find(el=>{
    return value == el.value
  })
  return result ? result.name : "-"
}

