<template>
  <a-drawer
      title="D类评级支付明细"
      placement="right"
      :width="'80vw'"
      :closable="true"
      @close="handleClose"
      :visible="showDrawer"
  >
    <LineTableList
        :list="data"
        :pagination="pagination"
        @changePage="handleChangePage"
        @changeSuccess="getDTypePayLineList('re')"
    />
  </a-drawer>
</template>
<script>
import {getDTypePayLineApi} from "@/views/cmsPage/checkManage/dList/_apis"
import LineTableList
  from "@/views/cmsPage/checkManage/dList/_components/RatingLinePopup/_components/LineTableList/index.vue"

export default {
  components: {LineTableList},
  data() {
    return {
      data: [],
      showDrawer: false,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        personApplyId: undefined
      },
    }
  },
  methods: {
    /** 展示数据 */
    async show(data) {
      this.params.personApplyId = data.id
      await this.getDTypePayLineList()
      this.showDrawer = true
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getDTypePayLineList();
    },
    /** 获取明细列表 */
    async getDTypePayLineList(type) {
      this.$loading.show()
      const res = await getDTypePayLineApi(this.params)
      this.$loading.hide(0)
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
      if (type) {
        this.$emit('changeSuccess')
      }
    },
    /** 关闭明细 */
    handleClose() {
      this.showDrawer = false
    }
  }
}
</script>
<style scoped lang="scss">

</style>