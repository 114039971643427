<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.userName"
          class="w-200 mb-5"
          placeholder='送评人昵称或userID'
      ></a-input>
      <a-input
          allowClear
          v-model="params.applyNo"
          class="w-200 ml-5 mb-5"
          placeholder='D类送评单号'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.ratingStatus"
          placeholder="评级状态"
      >
        <a-select-option
            v-for="item of dTypeRateStatusList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @changeSuccess="search"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/dList/_components/TableList"
import {D_TYPE_RATE_STATUS_TYPE_LIST} from "@/views/cmsPage/checkManage/_data"
export default {
  components: {
    TableList,
  },
  data() {
    return {
      data: [],
      tempData: [],
      dTypeRateStatusList: D_TYPE_RATE_STATUS_TYPE_LIST,
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        userName: undefined,
        applyNo: undefined,
        ratingStatus: undefined,
      },
    };
  },
  async mounted() {
    const { personApplyNo } = this.$route.query
    this.$set(this.params, "applyNo", personApplyNo)
    await this.getList()
  },
  methods: {
    async scanCodeEvent(code) {
      if (code) {
        this.params.barCode = code
        await this.getList()
      }
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/personRatingApply/list", {
        params: {
          ...this.params
        },
      });
      this.$loading.hide()
      if (res.status != 200) return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
