export const dqCheckDListTableColumns = [
    { title: "D类送评单号", align: "center", scopedSlots: { customRender: 'itemApplyNoSlot' } },
    { title: "送评用户", align: "center", scopedSlots: { customRender: "itemRateServiceUserNameSlot" }},
    { title: "送评数量", align: "center", dataIndex: 'coinAmount'},
    { title: "是否需要标版", align: "center", scopedSlots: { customRender: "itemVersionServiceStatusSlot" }},
    { title: "送评图片", align: "center", scopedSlots: { customRender: "itemRateServiceImageSlot" }, width: 250},
    { title: "送评状态", align: "center", scopedSlots: { customRender: "itemRateStatusSlot" }},
    { title: "送评时间", align: "center", dataIndex: 'createTime' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]

// "id": 1484,
//     "orderId": null,
//     "userId": 3479,
//     "personApplyId": 150,
//     "transactionId": null,
//     "payableMoney": 50.00,
//     "payMoney": 50.00, 扣除金额
//     "payStatus": 6, 2支付，6.退回，4扣除，
//     "payType": null,
//     "tradeNo": null,
//     "depositDesc": "评级确认退回费用",
//     "type": 1, 2时，returnTime === '' 显示退回按钮
//     "logisticsInfo": null,
//     "payTime": null,
//     "returnTime": "2024-07-30 11:57:50",
//     "createTime": "2024-07-30 11:57:49",
//     "updateTime": "2024-07-30 11:57:49",
//     "txnSeqno": "2707301603443504",
//     "refundSeqno": null,
//     "payModule": 0,
//     "singleRealPay": 0,
//     "deleteFlag": 0
/** 评级明细列表 */
export const dqCheckDTypeLineTableColumns = [
    { title: "支付状态", align: "center", scopedSlots: { customRender: 'itemPayStatusSlot' } },
    { title: "金额", align: "center", scopedSlots: { customRender: 'itemPayMoneySlot' } },
    { title: "支付时间", align: "center", dataIndex: 'payTime' },
    { title: "退还时间", align: "center", dataIndex: 'returnTime' },
    { title: "操作", align: "center", scopedSlots: { customRender: "itemActionSlot" }, width: 200 },
]
